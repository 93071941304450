import * as colors from './colors';
import { fonts, japaneseFonts } from './fonts';
import * as baseAnimations from '~/theme/animations/baseAnimations';
import { getFluidValue } from './utils/getClamp';
import { breaks } from './breakpoints';
import { isJapaneseFont } from './utils/getLanguagesFonts';
import { CSSObject } from '@emotion/react';

export type ThemeType = typeof theme;

declare module '@emotion/react' {
    export interface Theme extends ThemeType {}
}

export const fontSizes = {
    '2xs': getFluidValue(12),
    xs: getFluidValue(14),
    sm: getFluidValue(16),
    md: getFluidValue(18),
    lg: getFluidValue(20),
    xl: getFluidValue(25),
    '2xl': getFluidValue(30),
    '3xl': getFluidValue(35),
    '4xl': getFluidValue(40),
    '5xl': getFluidValue(50),
    '6xl': getFluidValue(60),
    '7xl': getFluidValue(70),
};

export const fontWeights = {
    regular: 300,
    semiBold: 400,
    bold: 700,
    black: 900,
};

export const lineHeights = {
    single: '1',
    compact: '1.2',
    body: '2.1875',
    caption: '1.21428571',
    base: '1.5',
    headline: '1.125',
    button: '1.1875',
};

export const letterSpacings = {
    xs: '0.01em',
    sm: '0.04em',
    md: '0.14em',
};

// TODO: Should this be removed, if we use design-token-typography?
export const baseFontStyles = (language: string) => {
    const baseFont = isJapaneseFont(language) ? japaneseFonts : fonts;

    return {
        fontFamily: baseFont.body,
        fontWeight: fontWeights.regular,
    };
};

export const borderRadius = {
    xs: getFluidValue(15),
    sm: `${getFluidValue(10, 5)} ${getFluidValue(10, 5)} ${getFluidValue(10, 5)} ${getFluidValue(
        10,
        5,
    )} / ${getFluidValue(13, 7)} ${getFluidValue(13, 7)} ${getFluidValue(13, 7)} ${getFluidValue(
        13,
        7,
    )}`,
    md: `${getFluidValue(45, 24)} ${getFluidValue(15, 8)}`,
};

export const elevations = {
    sm: `rgba(149, 157, 165, 0.1) 0px 8px 24px 0px`,
    smDarker: `rgba(149, 157, 165, 0.2) 0px 8px 24px 0px`,
    md: `0px 0px 95px -35px rgb(0 0 0 / 25%)`,
    mdTight: `0px 8px 24px 0px rgba(149, 157, 165, 0.2)`,
    lg: `0px 0px 120px -15px rgb(0 0 0 / 25%)`,
};

export const contentMaxWidth = 1440;

export const sizes = {
    // Limit content
    contentMaxWidth: breaks.contentMax,

    // Limit page width. Background colors etc.
    pageMaxWidth: breaks.pageMax,

    content: 'min(88vw, 1690px)',

    iconXs: '10px',
    iconSm: '15px',
    iconMd: '20px',
    iconLg: '30px',
    iconXl: '35px',
    icon2XL: '45px',
    icon3xl: '130px',

    // Default Width for side Drawer
    drawerWidth: 480,
    mobileDrawerWidth: 300,
} as const;

export const theme = {
    direction: 'ltr',
    colors,
    animations: {
        ...baseAnimations,
    },
    fontVariants: {
        display1: {},
        display2: {},
        display3: {},
        display4: {},
        display5: {},
        display6: {},
        caption: {},
        captionSm: {},
        body: {},
        bodySm: {},
        button: {} as CSSObject,
    },
    fonts,
    fontWeights,
    lineHeights,
    letterSpacings,
    fontSizes,
    spaces: {
        '1': getFluidValue(4),
        '2': getFluidValue(8),
        '3': getFluidValue(12),
        '4': getFluidValue(16),
        '5': getFluidValue(20),
        '6': getFluidValue(24),
        '7': getFluidValue(32),
        '8': getFluidValue(64),
        '9': getFluidValue(80),
        '10': getFluidValue(128),
        gutter: 25,
        mobileGutter: 20,
    },
    borderRadius: {
        ...borderRadius,
        card: borderRadius.md,
        input: borderRadius.xs,
        dropdown: borderRadius.xs,
        button: borderRadius.xs,
    },
    elevations: {
        ...elevations,
        card: elevations.md,
        dropdown: elevations.mdTight,
        sidePanel: elevations.lg,
    },
    sizes: sizes,
    navigationHeight: {
        desktop: 170,
        desktopSmall: 150,
        mobile: 70,
    },
    zIndexes: {
        '1': 100,
        '2': 200,
        '3': 300,
        '4': 400,
        max: 999,
        portalLow: 1500,
        navigation: 3000,
        portalHigh: 9999,
    },
    easings: {
        smoothEase: 'cubic-bezier(0.35, 0, 0.15, 1)',
    },
    moduleHeight: {
        small: 380,
        large: 800,
    },
};
