import { Translations } from '~/shared/utils/translation';
import { fetcher } from './fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { isSSR } from '~/shared/utils';

/**
 * Helper method for requesting translations for a particular culture
 */
export function fetchTranslations(url: string, culture: string, market: string) {
    const { API_URL } = publicRuntimeConfig();
    const proxyRoute = `/${culture}/${market}/api/local-proxy/content/translations`.toLowerCase();
    const serverRoute = `${API_URL}/${culture}/${market}/api/content/translations`.toLowerCase();
    const route = isSSR ? serverRoute : proxyRoute;
    return fetcher<Translations>(
        route,
        url.includes('preview=true')
            ? {
                  preview: 'true',
              }
            : {}
    );
}
