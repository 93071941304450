import React from 'react';
import { Frame } from '~/lib/data-contract';

type FrameProviderProps = { initialData?: Frame; isMyKompan?: boolean };

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FrameContext = React.createContext<FrameProviderProps>(undefined!);

export const FrameProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<FrameProviderProps>) => {
    return <FrameContext.Provider value={{ ...otherProps }}>{children}</FrameContext.Provider>;
};
