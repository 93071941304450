import React from 'react';
import { Translations } from '../model/translationModel';

type TranslationProviderProps = {
    language: string;
    market: string;
    initialTranslations?: Translations;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const TranslationContext = React.createContext<TranslationProviderProps>(undefined!);

export const TranslationProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<TranslationProviderProps>) => {
    return (
        <TranslationContext.Provider value={{ ...otherProps }}>
            {children}
        </TranslationContext.Provider>
    );
};
