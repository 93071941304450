import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledGutter = styled.div(({ theme }) => ({
    paddingLeft: theme.spaces.mobileGutter,
    paddingRight: theme.spaces.mobileGutter,
    [breakpoints.sm]: {
        paddingLeft: theme.spaces.gutter,
        paddingRight: theme.spaces.gutter,
    },
}));
