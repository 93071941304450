/////
// This file is autogenerated from Figma!
/////
export const grayscale20 = '#d9d9db';
export const grayscale30 = '#E4E4E4';
export const grayscale80 = '#555554';
export const grayscale100 = '#000';
export const blue10 = '#e5f1f6';
export const red20 = '#f0cfd2';
export const yellow100 = '#f7a60';
export const grayscale5 = '#f2f2f2';
export const red80 = '#bf3c46';
export const green40 = '#b2ddad';
export const blue100 = '#0075a6';
export const yellow20 = '#fdedcc';
export const red100 = '#b5111e';
export const grayscale60 = '#868686';
export const green80 = '#65bc5c';
export const grayscale40 = '#b1b1b3';
export const red40 = '#e1a0a5';
export const green60 = '#8bcd85';
export const green20 = '#d8eed6';
export const blue60 = '#66acca';
export const blue40 = '#99c8db';
export const grayscale0 = '#ffffff';
export const blue20 = '#cce3ed';
export const green10 = '#ecf7eb';
export const yellow10 = '#fef6e5';
export const yellow80 = '#f9b833';
export const yellow40 = '#fcdb99';
export const blue80 = '#3391b8';
export const red10 = '#f8e7e8';
export const yellow60 = '#faca66';
export const green100 = '#3eab33';
export const red60 = '#d37078';
export const grayscale10 = '#e5e5e5';
