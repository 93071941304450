import { css, Global } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { fontsFace } from '~/theme/fonts-face';
import { baseFontStyles, theme } from '../../theme';
import { isJapaneseFont } from '~/theme/utils/getLanguagesFonts';
import { japaneseFontsFace } from '~/theme/japanese-fonts-face';

/**
 * An exampel of how to add a font-face
 * @font-face {
 *     font-family: 'Myriad Pro';
 *     src: url(...path from import) format('woff2'),
 *          url(...path from import) format('woff');
 *     font-weight: normal;
 *     font-style: normal;
 *     font-display: swap;
 * }
 */

export const GlobalStyles = ({ language }: { language?: string }) => {
    return (
        <Global
            styles={css`
                ${emotionReset}
                ${language && isJapaneseFont(language) ? japaneseFontsFace : fontsFace}
    
                html {
                    box-sizing: border-box;
                    overflow-y: scroll;
                    height: -webkit-fill-available;
                    font-size: 0.625em; // 0.625em = 10px
                }

                html[lang='ja'],
                html[lang='zh'],
                html[lang='ko'] {
                    line-break: strict;
                }

                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }

                body {
                    height: 100vh;
                    /* mobile viewport bug fix */
                    height: -webkit-fill-available;
                    margin: 0;
                    // TODO: Should we use values from design-token-typography?
                    ${baseFontStyles(language ?? '')}
                    font-size: 1.6rem;
                    -webkit-font-smoothing: antialiased;
                    text-rendering: optimizelegibility;
                }

                input,
                button,
                textarea,
                select {
                    font-family: inherit;
                }

                .grecaptcha-badge {
                    visibility: hidden;
                }

                [data-radix-portal] {
                    position: fixed !important;
                }

                /* progress bar */
                #nprogress .bar {
                    background: ${theme.colors.red} !important;
                    height: 2px;
                    z-index: ${theme.zIndexes.portalHigh};
                }

                #nprogress .peg {
                    box-shadow: none;
                }

                .swiper-wrapper {
                    transition: transform 500ms cubic-bezier(0.54, 0, 0.34, 1) !important;
                }
            `}
        />
    );
};
