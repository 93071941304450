import { Fetch, HeadersParam } from './models/fetch';

type ParamType = {
    [key: string]: string | string[] | undefined;
};

type MethodType = 'POST' | 'GET' | 'PUT' | 'DELETE';

// Get arbitrary severity score for request time. Used for better grouping of logs from server.
const getPerformanceSeverity = (time: number) => {
    const seconds = time / 1000;
    if (seconds > 10) return 5;
    if (seconds > 5) return 4;
    if (seconds > 2) return 3;
    if (seconds > 1) return 2;
    return 1;
};

/**
 * Helper method for fetching content from the API
 */
export async function fetcher<T>(
    endpoint: string,
    params: ParamType = {},
    headers: HeadersParam = {},
    method: MethodType = 'GET',
    body = '',
): Promise<Fetch<T>> {
    // Kill the request if it doesn't resolve in 30 seconds
    const signal = AbortSignal.timeout(30000);
    // Add query params
    const searchParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]) => {
        searchParams.append(key, String(value));
    });
    const searchParamsQuery = searchParams.toString();
    const url = `${endpoint}${searchParamsQuery?.length ? `?${searchParamsQuery}` : ''}`;

    const startDate = new Date();
    const startTime = performance.now();
    if (typeof window == 'undefined') {
        // Filter out headers that should not be logged
        const blackListHeaders = ['authorization'];
        const outputHeaders: { [key: string]: string } = {};

        Object.entries(headers).forEach(([key, value]) => {
            if (blackListHeaders.includes(key.toLowerCase())) return;
            outputHeaders[key] = value;
        });
        console.info('Querying', url, outputHeaders);
    }

    const response = await fetch(url, {
        method: method,
        headers: {
            ...headers,
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: method != 'GET' ? body : undefined,
        keepalive: true,
        signal,
    });
    const { status, statusText } = response;

    const endTime = performance.now();
    // Needs to run in server as we're usign the appInights object from Node instance.
    if (typeof window == 'undefined' && process.env.NODE_ENV === 'production') {
        const timing = Math.round(endTime - startTime);
        const severity = getPerformanceSeverity(timing);
        const customProperties = {
            url,
            timing,
            severity,
        };

        if (severity >= 3) {
            globalThis?.appInsights?.trackEvent({
                name: 'SpaServerFetchSlowRequest',
                measurements: {
                    timing: timing,
                },
                time: startDate,
                properties: customProperties,
            });
        }
        globalThis?.appInsights?.trackMetric({
            value: timing,
            name: 'SpaServerFetchRequestTime',
            properties: customProperties,
            time: startDate,
        });

        globalThis?.appInsights?.trackDependency({
            data: url,
            duration: timing,
            resultCode: status,
            success: response.ok,
            name: 'SpaServerFetch',
            dependencyTypeName: 'FETCH',
            properties: customProperties,
            time: startDate,
        });

        console.log(`Querying ${url} took ${timing}ms. Performance severity level: ${severity}`);
    }

    if (status >= 500) {
        console.error(`Failed to fetch API with url:${url}`);
        console.error(statusText);
    }

    return response;
}
