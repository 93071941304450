import { TranslationKey } from '~/lib/data-contract';
import { useContext, useState } from 'react';

import { TranslationContext } from '../context/TranslationProvider';

import { namespacedKeys } from '../utils/namespacedKeys';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { fetchTranslations } from '~/lib/fetchTranslations';

const useTranslationContext = () => {
    return useContext(TranslationContext);
};

/**
 * Translation hook, will load receive initial query data from dehydrated props in getPageProps and fetch new values when culture changes


 */
export const useTranslation = () => {
    const router = useRouter();
    const url = router.asPath;
    const { market, language } = useTranslationContext();
    const [warnings, setWarnings] = useState<string[]>([]);

    const { data: data, isLoading } = useQuery(['translations', language, market], {
        queryFn: async () => {
            const rawTranslations = await fetchTranslations(url, language, market).then((res) =>
                res.json()
            );
            return namespacedKeys(rawTranslations);
        },
        keepPreviousData: true,
    });

    const translate = (key: TranslationKey, fallback = '') => {
        const translation = data?.[key];
        if (!translation && !warnings.includes(key) && !fallback) {
            console.info(`%cuseTranslation: Missing translation: '${key}'`, 'color: #eed202');
            setWarnings((warnings) => [...warnings, key]);
        }

        return translation ? translation : fallback;
    };

    return { translate, isLoading };
};
